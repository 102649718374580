<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DataViewer from "@/components/elements/DataViewer.vue";
import Swal from "sweetalert2";
import {
  GetConsultantById,
  updateDocumentData,
    setIsPaid
} from "@/services/consultant.js";
import moment from "moment";
/**
 * Typography component
 */
export default {
  page: {
    title: "Consultant",
    meta: [{ name: "Consultant", content: appConfig.description }],
  },
  components: { DataViewer, Layout, PageHeader },
  data() {
    return {
      title: this.$t("consultant"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.consultant.text"),
          href: "all",
        },
        {
          text: this.$t("consultant_information"),
          active: true,
        },
      ],
      data: [
        { key: this.$t("title"), value: "lorem" },
        {
          key: this.$t("meeting_url"),
          type: "link",
          value: "httpasdasdasdad/asdads",
        },

        { key: this.$t("client_name"), value: "ahmed" },
        { key: this.$t("start_date"), value: 10 },
        { key: this.$t("end_date"), value: 5 },
      ],
      editData: { start: "" },
      done: false,
      paidLoading:false,
      form: { name: "", Phone: "", address: "", password: "" },
    };
  },
  methods: {
    edit() {
      updateDocumentData(
        this.$route.params.id,
        {
          startDate: this.editData.start,
        },
        this.data.clientData.uid
      ).then(() => {
        this.successmsg();
        this.$bvModal.hide("modal-backdrop");
      });
    },
    async paid() {
      this.paidLoading = true;
      try {
        const res = await setIsPaid(this.data.consultantData.uuid);
        if(res){
          this.loadConsultant(this.$route.params.id);
          this.successmsg();
          this.$bvModal.hide("modal-backdrop-paid");
        }
      }catch (err){
        console.log(err)
      }finally {
        this.paidLoading = false;
      }

    },
    close() {
      updateDocumentData(
        this.$route.params.id,
        {
          isClosed: true,
        },
        this.data.clientData.uid
      ).then(() => {
        this.successmsg();
        this.$bvModal.hide("modal-backdrop-cancel");
      });
    },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been updated",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    loadConsultant(id) {
      GetConsultantById(id).then((data) => {
        this.data = data;
        // console.log(data);
      });
    },
  },
  computed: {
    consultant() {
      console.log(this.data);
      if (this.data.clientData) {
        const data = [
          { key: this.$t("title"), value: this.data?.consultantData?.title },

          {
            key: this.$t("client_name"),
            value: this.data?.clientData?.display_name,
            internalLink: this.data?.clientData?.uid
          },
          {
            key: this.$t("start_date"),
            value: moment(this.data?.consultantData?.startDate).calendar(),
          },
          {
            key: this.$t("duration"),
            value: this.data?.consultantData?.duration + " min",
          },
          {
            key: this.$t("price"),
            value: this.data?.consultantData?.totalAmount + " KWD",
          },
          {
            key: this.$t("status"),
            value: this.data?.consultantData?.isClosed
              ? this.$t("closed")
              : this.data?.consultantData?.isPaid
              ? this.$t("paid")
              : this.$t("not_paid"),
          },
        ];
        if(this.data?.consultantData?.meetType){
          data.push(
              {
                key: this.$t('howToCommunicate'),
                value: this.$t(this.data?.consultantData?.meetType)
              }
          )
        }
        if (!this.data.consultantData.isClosed) {
          if (this.data.consultantData.joinURL) {
            data.push({
              key: this.$t("meeting_url"),
              type: "link",
              value: this.data?.consultantData?.joinURL,
            });
          }
        } else {
          data.push({
            key: this.$t("meeting_url"),
            value: "meeting is closed",
          });
        }
        return data;
      } else {
        const data = [
          {
            key: this.$t("start_date"),
            value: moment(this.data?.consultantData?.startDate).calendar(),
          },
          {
            key: this.$t("end_date"),
            value: moment(this.data?.consultantData?.endDate).calendar(),
          },
          {
            key: this.$t("busyTime"),
            value: this.$t("busyTime"),
          },
        ];
        return data;
      }
    },
    clientData() {
      return [
        {
          key: this.$t("name"),
          value: this.data?.clientData?.display_name,
          internalLink: this.data?.clientData?.uid
        },
        {key: this.$t("phone"), value: this.data?.clientData?.phone_number},
        {
          key: this.$t("email"),
          value: this.data?.clientData?.email,
        },
      ];
    },
  },
  created() {
    this.loadConsultant(this.$route.params.id);
    console.log(this.data);
  },
};
</script>

<template>
  <Layout
    ><PageHeader :title="title" :items="items" />
    <DataViewer :title="$t('profile')" :data="consultant" />
    <DataViewer :title="$t('client_information')" :data="clientData"/>
    <div class="row">
      <div class="col-6">
        <div class="card">
          <!-- button for showing event update modal -->
          <b-button
            v-b-modal.modal-backdrop
            variant="warning"
            v-if="!data?.consultantData?.isClosed && data.clientData"
          >
            {{ $t("edit_consultant") }}
          </b-button>
          <!-- modal for event date update -->
          <b-modal
            no-close-on-backdrop
            id="modal-backdrop"
            :title="$t('edit_consultant')"
            centered
            title-class="font-18"
          >
            <!-- disable modal default Buttons -->
            <template #modal-footer>
              <div></div>
            </template>
            <!-- modal form -->
            <form @submit.prevent="edit">
              <label for="date-time-start">{{ $t("start_date") }}</label>
              <b-form-input
                id="date-time-start"
                v-model="editData.start"
                type="datetime-local"
                required
              ></b-form-input>
              <!-- buttons for modal  -->
              <b-row
                ><b-col cols="8"></b-col>
                <b-col cols="2" style="margin-top: 10px"
                  ><b-button @click="$bvModal.hide('modal-backdrop')">{{
                    $t("cancel")
                  }}</b-button>
                </b-col>
                <b-col cols="2" style="margin-top: 10px">
                  <b-button variant="primary" type="submit">{{
                    $t("ok")
                  }}</b-button>
                </b-col></b-row
              >

              <!-- End buttons -->
            </form>
            <!-- end form -->
          </b-modal>
          <!-- end date update modal -->
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <!-- button for showing event cancel modal -->
          <b-button
            v-b-modal.modal-backdrop-cancel
            variant="danger"
            v-if="!data?.consultantData?.isClosed && data.clientData"
          >
            {{ $t("close_consultant") }}
          </b-button>
          <!-- event cancel modal -->
          <b-modal
            no-close-on-backdrop
            id="modal-backdrop-cancel"
            title="Cancel Consultant"
            centered
            title-class="font-18"
          >
            <!-- disable modal default buttons -->
            <template #modal-footer>
              <div></div>
            </template>
            {{ $t("are_you_sure_want_to_close_this_consultant") }}
            <!-- modal buttons -->
            <b-row
              ><b-col cols="8" style="margin-top: 10px"></b-col>
              <b-col cols="2" style="margin-top: 10px">
                <b-button @click="$bvModal.hide('modal-backdrop-cancel')">{{
                  $t("cancel")
                }}</b-button>
              </b-col>
              <b-col cols="2" style="margin-top: 10px">
                <b-button variant="primary" @click="close">{{
                  $t("ok")
                }}</b-button>
              </b-col></b-row
            >
            <!-- end modal buttons -->
          </b-modal>
          <!-- end event cancel modal  -->
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <!-- button for showing event cancel modal -->
          <b-button
            v-b-modal.modal-backdrop-paid
            variant="info"
            v-if="
              !data?.consultantData?.isClosed &&
              !data?.consultantData?.isPaid &&
              data.clientData
            "
          >
            {{ $t("set_paid") }}
          </b-button>
          <!-- event cancel modal -->
          <b-modal
            no-close-on-backdrop
            id="modal-backdrop-paid"
            title="pay Consultant"
            centered
            title-class="font-18"
          >
            <!-- disable modal default buttons -->
            <template #modal-footer>
              <div></div>
            </template>
            {{ $t("are_you_sure_want_to_set_this_consultant_as_paid") }}
            <!-- modal buttons -->
            <b-row
              ><b-col cols="8" style="margin-top: 10px"></b-col>
              <b-col cols="2" style="margin-top: 10px">
                <b-button @click="$bvModal.hide('modal-backdrop-cancel')">{{
                  $t("cancel")
                }}</b-button>
              </b-col>
              <b-col cols="2" style="margin-top: 10px">
                <b-overlay
                    :show="paidLoading"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                >
                <b-button variant="primary" @click="paid">{{
                  $t("ok")
                }}</b-button>
                </b-overlay>
              </b-col></b-row
            >
            <!-- end modal buttons -->
          </b-modal>
          <!-- end event cancel modal  -->
        </div>
      </div>
    </div>
  </Layout>
</template>
