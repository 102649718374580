import axios from "axios";
// Define the URL of your Firebase Cloud Function
const firebaseFunctionURL =
  "https://us-central1-law-office-flutterflow.cloudfunctions.net/app/consultants";
const firebaseFunctionURL1 =
  "https://us-central1-law-office-flutterflow.cloudfunctions.net/app/timePeriods";
import "firebase/firestore";
const agentID = JSON.parse(localStorage.getItem("auth.currentUser"));
console.log(agentID);

import firebase from "firebase/app";
import { pushNotification, create } from "./notification";
import moment from "moment";
const db = firebase.firestore();
const collectionRef = db.collection("CosultantRequest");
const users = db.collection("users");

export function GetAllConsultant() {
  return new Promise((resolve, reject) => {
    const data = [];

    collectionRef
      .get()
      .then(async (querySnapshot) => {
        // Create an array of promises for fetching client data
        const promises = querySnapshot.docs.map(async (doc) => {
          const consultant = doc.data();
          const clientId = consultant?.clientID?.id;
          let clientName = "";

          if (clientId) {
            try {
              const clientDoc = await users.doc(clientId).get();
              if (clientDoc.exists) {
                clientName = clientDoc.data().display_name;
              } else {
                console.log("Client not found");
              }
            } catch (error) {
              console.error("Error fetching client data:", error);
            }
          }

          data.push({
            id: doc.id,
            start: nanosecondsToISO8601(doc.data().BookingStart.seconds),
            end: nanosecondsToISO8601(doc.data().BookingEnd.seconds),
            clientName,
            clientId,
            status: consultant.isPaid,
            ...consultant,
          });
        });

        // Wait for all client data fetches to complete
        await Promise.all(promises);

        resolve(data);
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
        reject(error);
      });
  });
}

export async function GetConsultantById(id) {
  try {
    let doc = await collectionRef.doc(id).get();
    if (!doc.exists) {
      const findByFFUID = await collectionRef
        .where("FFUID", "==", +id)
        .limit(1)
        .get();
      findByFFUID.forEach((el) => {
        doc = el;
      });
    }
    if (doc.exists) {
      const consultantData = doc.data();

      const clientID = consultantData?.clientID?.id;
      let clientData = "";

      try {
        const clientDoc = await users.doc(clientID).get();

        if (clientDoc.exists) {
          clientData = clientDoc.data();
        } else {
          console.log("Client not found");
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }

      const data = {
        id: doc.id,
        // createdAt: nanosecondsToISO8601(consultantData.created_at.seconds),
        start: doc.data().BookingStart,
        end: doc.data().BookingEnd,
        consultantData,
        clientData,
      };
      console.log(data);
      return data;
    } else {
      console.log("consultant not found");
      throw new Error("consultant not found");
    }
  } catch (error) {
    console.error("Error getting consultant document:", error);
    throw error;
  }
}
export async function updateDocumentData(docId, updatedData, clientID) {
  console.log(docId, updatedData, clientID);
  const docRef = collectionRef.doc(docId);
  // console.log(updatedData);
  try {
    await docRef.update(updatedData);
    console.log(updatedData);
    const body = `تم تحديث موعد الاستشارة الخاصة بك الي ${moment(
      updatedData.startDate
    ).format("YYYY-MM-DD HH:mm")}`;
    const title = "تم تحديث موعد استشارتك";
    pushNotification(clientID, body, title);
    create(clientID, agentID, title, body, "consultant", docId);
    return true;
  } catch (error) {
    console.error("Error updating document: ", error);
    return false;
  }
}
export async function setIsPaid(uuid) {
  try {
    await axios.get(
      `https://us-central1-law-office-flutterflow.cloudfunctions.net/app/consultants/paid/${uuid}`
    );
    return true;
  } catch (err) {
    return false;
  }
}
export function AddBusyTime(BookingStart, BookingEnd, title) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${firebaseFunctionURL}/busy`, {
        BookingStart,
        BookingEnd,
        title,
      })
      .then((res) => {
        // console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error getting agents:", err);

        reject(err);
      });
  });
}
export function GetAllPrices() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${firebaseFunctionURL1}`)
      .then((res) => {
        // console.log(res);
        resolve(res.data.timePeriods);
      })
      .catch((err) => {
        console.error("Error getting agents:", err);

        reject(err);
      });
  });
}
export function EditPrice(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${firebaseFunctionURL1}/${id}`, data)
      .then((res) => {
        // console.log(res);
        resolve(res);
      })
      .catch((err) => {
        console.error("Error getting agents:", err);

        reject(err);
      });
  });
}
export function AddPrice(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${firebaseFunctionURL1}`, data)
      .then((res) => {
        // console.log(res);
        resolve(res);
      })
      .catch((err) => {
        console.error("Error adding price:", err);

        reject(err);
      });
  });
}
export function DeletePrice(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${firebaseFunctionURL1}/${id}`)
      .then((res) => {
        // console.log(res);
        resolve(res);
      })
      .catch((err) => {
        console.error("Error getting agents:", err);

        reject(err);
      });
  });
}
export function nanosecondsToISO8601(seconds) {
  const milliseconds = seconds * 1000; // Convert nanoseconds to milliseconds
  const date = new Date(milliseconds);
  return date;
}
